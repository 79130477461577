import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import cmblapi from '@/utils/cmblapi.min'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

let mBankPromise: Promise<any> | null = null

function login (self: any, expand: object) {
  if (mBankPromise) {
    return mBankPromise
  }
  mBankPromise = new Promise(async (resolve, reject) => {
    cmblapi.merchantLogin({
      ...expand,
      async success (cmbRes: any) {
        console.log('success', cmbRes)
        if (cmbRes.resultType === 'Y') {
          const resLogin = await httpHelper.cmbLogin(cmbRes.cryptType, cmbRes.body)
          resolve(resLogin)
        }
      },
      fail (cmbRes: any) {
        console.log('fail>>>', cmbRes)
        self.$toasted.show('授权失败！')
        setTimeout(() => {
          history.back()
        }, 2000)
      }
    })
  })
  return mBankPromise
}

const mbank: any = {
  login
}

export default mbank
