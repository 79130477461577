
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'Ban',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public reLoginUrl: string = ''
  async created () {
    let { url = '' } = this.$route.query
    this.reLoginUrl = decodeURIComponent(url.toString())
  }

  @Emit()
  public relogin () {
    if (this.reLoginUrl) {
      location.replace(this.reLoginUrl)
    }
  }
}
