
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import InputItem from '@/components/InputItem.vue'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import md5 from 'js-md5'
import Authorization from '@/components/authorization.vue'
import GlobalMixin from '@/mixins/global'
import mbank from '@/partner/mbank'
import okpay from '@/partner/okpay'
import getCurrentQuery from '@/utils/querystrings'
import cookies from 'js-cookie'

const Fw = (window as any).Fw
function checkYCVersion () {
  return new Promise((resolve) => {
    console.log(1)
    const w = window as any
    w.ycVersion = function (res: any) {
      console.log(2, res)
      resolve(res)
    }
    Fw.device.api.getSystemVersion('ycVersion')
    // Fw.device.api.getSystemVersion((res: any) => {
    //   console.log(2, res)
    //   resolve(res)
    // })
  })
}
function getOnlyIC (shopid: string, MD5String: string) {
  /*
  callbackGetOnlyIC:回调函数
  shopid:商户id，测试环境:1100529310001000005。  生产环境：3200001810001000093
  time：系统当前时间（毫秒数），传字符串。例：1566281448677
  sign：签名。 （商户id+当前时间戳+约定code码）取md5值，即md5(shipi+time+code);
  */
  // var shopid = '';//生产环境
  // var shopid = '2020123017260101001'// 测试环境
  // var shopid = '1100529310001100202' // 生产环境
  // var MD5String = 'fa1882a9eafd47589792e70e64180ddc' // 测试环境
  // var MD5String = '6F51400644E217348B2D0BF6ED02E7FE' // 生产环境
  var time = Date.now().toString()
  var signBefore = shopid + time + MD5String
  var sign = md5(signBefore)
  console.log('>>>', shopid, MD5String, time, sign)
  Fw.device.api.getOnlyIC('psbcCallback', shopid, time, sign)
}

async function getAuthorization (param: any) {
  console.log('youchu param >>>', param)
  // var param = {
  //   appId: 'fa1882a9eafd47589792e70e64180ddc',
  //   redirectUri: 'https://m.benlai.com/',
  //   responseType: 'code',
  //   scope: 'upapi_phone',
  //   state: '12211',
  //   tran_code: '81',
  //   fn: 'uccallback'
  // }
  // let res: any = await checkYCVersion()
  // res = typeof res === 'string' ? JSON.parse(res) : res
  // // const version = res.CLIENT_VER
  // console.log(res, typeof res, res.CLIENT_VER)

  // const arr = res.CLIENT_VER.split('.')
  // console.log(0)
  // const version = parseInt(arr[0]) * 1000 * 1000 + parseInt(arr[1]) * 1000 + parseInt(arr[2])
  // console.log(version, 4001006)
  // if (Fw.device.Device.is_ios && version <= 4001006) {
  //   console.log(1)
  //   Fw.device.api.getAuthorization(param.old)
  //   console.log(2)
  // } else {
  //   console.log(3)
  // const appId = param.appId
  // const time = Date.now().toString()
  // const secret = 'SpnIOOXC0K0JfjTJwzWqYQusD7nsobSQ'
  // const sign = md5(appId + time + secret)
  // // eslint-disable-next-line camelcase
  // const tran_code = param.tran_code
  // const fn = param.fn
  // const yc = {
  //   appId,
  //   sign,
  //   time,
  //   tran_code,
  //   fn
  // }
  const p = {
    ...param.new
  }
  console.log('new youchu param >>>', p)
  Fw.device.api.getNewAuthorization(p)
  console.log(4)
  // }
  // alert(2222)
}

@Component({
  name: 'passportLogin',
  components: {
    'input-item': InputItem,
    'authorization': Authorization
  },
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  async created () {
    const self = this
    const res = await httpHelper.requestThirdConfig()
    if (res.value.env === 3 && res.value.type === 23) {
      const bank = await mbank.login(self, res.value.expand)
      this.goTo(bank, 9, 9)
    } else if (res.value.env === 1 && res.value.type === 24) {
      const obj = {
        from: getCurrentQuery('from') || '',
        mobile: getCurrentQuery('mobile') || '',
        uid: getCurrentQuery('uid') || '',
        timestamp: getCurrentQuery('timestamp') || '',
        sign: getCurrentQuery('sign') || ''
      }
      const oklife = await okpay.login(obj)
      cookies.set('anfubao', getCurrentQuery('mobile') || '', {
        domain: '.benlai.com'
      })
      this.goTo(oklife, 9, 9)
    } else if (res.value.env === 1 && res.value.type === 26) {
      const data:any = {
        auth_code: getCurrentQuery('code') || ''
      }
      // const maidanba = await httpHelper.getAuthLogin(data, 'authorize/maidanba')
      // this.goTo(maidanba, 9, 9)
      try {
        const maidanba = await httpHelper.getAuthLogin(data, 'authorize/maidanba')
        this.goTo(maidanba, 9, 9)
      } catch (err) {
        this.goTo(err, 9, 9)
        this.$toasted.show(err.msg)
      }
    } else if (res.value.env === 1 && res.value.type === 27) {
      // const self = this
      // (window as any).psbcCallback = async function (resMac: any) {
      //   console.log('>>>>', resMac);
      // const code = getCurrentQuery('code') || ''
      // if (!code) {
      (window as any).uccallback = async function (res: any) {
        console.log('>>>>>>', res)
        const json = typeof res === 'string' ? JSON.parse(res) : res
        const code = json.code
        if (!code) {
          Fw.device.api.goBack()
          return
        }
        const data: any = {
          auth_code: code
        }
        try {
          const maidanba = await httpHelper.getAuthLogin(data, 'authorize/youchu')
          console.log(JSON.stringify(maidanba))
          self.goTo(maidanba, 9, 9)
        } catch (err) {
          console.log(JSON.stringify(err))
          self.goTo(err, 9, 16)
          self.$toasted.show(err.msg)
        }
      }
      console.log(111)
      getAuthorization(res.value.expand)
      // }
      // }
      // debugger
      // getOnlyIC(res.value.expand.old.shopid, res.value.expand.old.md5String)
    } else if (res.value.env === 2 && res.value.type === 28) {
      // 买单吧
      let data = getCurrentQuery('data') || '{}'
      console.log(data)
      data = JSON.parse(decodeURIComponent(data))
      console.log(data)
      // const maidanba = await httpHelper.getAuthLogin(data, 'authorize/maidanba')
      // this.goTo(maidanba, 9, 9)
      try {
        const maidanba = await httpHelper.getAuthLogin(data, 'authorize/yuejingunion')
        this.goTo(maidanba, 9, 9)
      } catch (err) {
        this.goTo(err, 9, 9)
        this.$toasted.show(err.msg)
      }
    } else if (res.value.env === 2 && res.value.type === 29) {
      // 紫金银行
      const data:any = {
        Token: getCurrentQuery('token') || ''
      }
      try {
        const zijinrcb = await httpHelper.getAuthLogin(data, 'authorize/zijinrcb')
        this.goTo(zijinrcb, 9, 9)
      } catch (err) {
        this.goTo(err, 9, 9)
        this.$toasted.show(err.msg)
      }
    } else if (res.value.env === 2 && res.value.type === 32) {
      // 兜礼
      let data = getCurrentQuery('data') || '{}'
      console.log(data)
      data = JSON.parse(decodeURIComponent(data))
      console.log(data)
      try {
        const douli = await httpHelper.getAuthLogin(data, 'authorize/doooly')
        this.goTo(douli, 9, 9)
      } catch (err) {
        this.goTo(err, 9, 9)
        this.$toasted.show(err.msg)
      }
    } else if (res.value.env === 2 && res.value.type === 33) {
      // 工行
      let data = getCurrentQuery('data') || '{}'
      console.log(data)
      data = JSON.parse(decodeURIComponent(data))
      console.log(data)
      try {
        console.log(decodeURIComponent(getCurrentQuery('redirect')))
        const icbc = await httpHelper.getAuthLogin(data, 'authorize/icbc')
        const redi = decodeURIComponent(getCurrentQuery('redirect'))
        if (redi) {
          location.replace(redi)
          return
        }
        this.goTo(icbc, 9, 9)
      } catch (err) {
        this.$toasted.show(err.msg)
        // const redi = decodeURIComponent(getCurrentQuery('redirect'))
        // if (redi) {
        //   location.replace(redi)
        //   return
        // }
        // this.goTo(err, 9, 9)
      }
    } else if (res.value.env === 2 && res.value.type === 36) {
      // 深能之家
      let data = getCurrentQuery('data') || '{}'
      console.log(data)
      data = JSON.parse(decodeURIComponent(data))
      console.log(data)
      try {
        const snzj = await httpHelper.getAuthLogin(data, 'authorize/snzj')
        this.goTo(snzj, 9, 9)
      } catch (err) {
        this.goTo(err, 9, 9)
        this.$toasted.show(err.msg)
      }
    }
  }
}
