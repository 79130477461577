import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import cmblapi from '@/utils/cmblapi.min'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import configs from '@/configs'

let oklifePromise: Promise<any> | null = null

function login (obj: object) {
  if (oklifePromise) {
    return oklifePromise
  }
  oklifePromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: `authorize/safepass`,
        contentType: 'application/x-www-form-urlencoded',
        data: {
          ...obj
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      oklifePromise = null
    }
  })
  return oklifePromise
}

const oklife: any = {
  login
}

export default oklife
